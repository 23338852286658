import axios from 'redaxios';
import Accordion from "../../scripts/modules/accordion";
import Headroom from "headroom.js";

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);
  const header = document.querySelector('.main-header');
  const menuTrigger = document.querySelector('.menu-trigger');
  const navigation = document.querySelector('.main-navigation');
  const navigationLinks = document.querySelectorAll('.main-navigation__link');
  const videoPlayer = document.querySelector('.intro__video');
  const forms = document.querySelectorAll('.form--with-ajax');

  let mobile = null;

  function handleDOMContentLoaded() {
    menuTrigger.addEventListener('click', handleMenu);
    navigationLinks.forEach((el) => el.addEventListener('click', closeMenu))

    const headroom  = new Headroom(header, {
      offset: {
        up: 200,
        down: 200
      }/*,
      onUnpin: () => menuTrigger.classList.add('menu-trigger--with-header'),
      onPin: () => menuTrigger.classList.remove('menu-trigger--with-header')*/
    });
    headroom.init();

    new Accordion();

    mobile = window.matchMedia('(max-width: 767px)');
    handleFullWidthVideo(mobile);
    mobile.addListener(handleFullWidthVideo);

    forms.forEach((form) => {
      const entryId = form.subscribing_entry;
      const timesField = form.times;
      const commentField = form.comment;
      const remainingMessage = form.parentElement.querySelector('.message');

      timesField.addEventListener('change', (e) => {
        const value = e.target.value;
        if (value !== '') {
          axios.get(`/api/book-visit/${entryId.value}/counter`, { params: { 'time': value } })
            .then((value) => {
              const data = value.data;

              // Show message
              remainingMessage.innerHTML = `<p>${data.message}</p>`;

              if (data.message !== '') {
                remainingMessage.classList.remove('form__message--hidden');
              } else {
                remainingMessage.classList.add('form__message--hidden');
              }

              if (data.remainingCount > 0) {
                commentField.removeAttribute('disabled');
              } else {
                commentField.setAttribute('disabled', 'disabled');
              }
            })
        } else {
          remainingMessage.classList.add('form__message--hidden');
          remainingMessage.innerHTML = '';
          commentField.setAttribute('disabled', 'disabled');
        }
      });

      form.addEventListener('submit', handleFormSubmit);
    });
  }

  function handleFormSubmit(e) {
    e.preventDefault();

    const parent = e.target.parentElement;
    const successMessage = parent.querySelector('.form__message');
    const errorMessage = parent.querySelector('.form__message--error');
    const submit = parent.querySelector('button[type=submit]');

    const inputs = e.target.elements;
    const values = {};
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name !== '') {
        values[inputs[i].name] = inputs[i].value;

        if (inputs[i].nodeName === 'INPUT') {
          if (inputs[i].type === 'checkbox' && !inputs[i].checked) {
            values[inputs[i].name] = '0';
          }
        }
      }
    }

    successMessage.classList.add('form__message--hidden');
    errorMessage.classList.add('form__message--hidden');
    submit.classList.add('button--load');

    axios.post(e.target.getAttribute('action'), values, {
      headers: { 'X-Requested-With': 'XMLHttpRequest' }
    })
      .then((response) => {
        successMessage.classList.remove('form__message--hidden');
        submit.classList.remove('button--load');
      })
      .catch((error) => {
        errorMessage.classList.remove('form__message--hidden');
        submit.classList.remove('button--load');
      });
  }

  function handleMenu(e) {
    if (e.currentTarget.classList.contains('menu-trigger--opened')) {
      e.currentTarget.classList.remove('menu-trigger--opened');
      navigation.classList.remove('main-navigation--opened');
    } else {
      e.currentTarget.classList.add('menu-trigger--opened');
      navigation.classList.add('main-navigation--opened');
    }
  }

  function closeMenu() {
    menuTrigger.classList.remove('menu-trigger--opened');
    navigation.classList.remove('main-navigation--opened');
  }

  function handleFullWidthVideo(e) {
    const videoPath = videoPlayer.dataset.video;
    const videoMobilePath = videoPlayer.dataset.videoMobile;
    videoPlayer.setAttribute('src', e.matches ? videoMobilePath : videoPath);
  }

})();